import React from 'react'
import HeroText from '../../components/hero-text'
import styles from './leadership-hero.module.scss'

const LeadershipHero = () => {
    return (
        <section className={styles.main}>
            <img src="/dna.svg" alt="DNA" className={styles.dna} />
            <div className="container mb-20 flex flex-col-reverse md:flex-row justify-between items-center">
                <div className={styles.heroImage}>
                    <img src="/leadership-hero.png" alt="Invirsa Science"/>
                </div>
                <HeroText>
                    <h1>Rethinking <strong>ocular</strong> and <strong>respiratory therapy</strong>.</h1>
                    <p>After millions of years of co-evolution with multi-cellular organisms, the active molecule of INV-102 has become highly effective at triggering multiple targets associated with the DNA damage response.</p>
                </HeroText>
            </div>
        </section>
    )
}

export default LeadershipHero