import React from 'react'
import LeadershipHero from '../fragments/leadership/leadership-hero'
import LeadershipTeam from '../fragments/leadership/leadership-team'
import MainLayout from '../layout/main-layout'

const Leadership = () => {
    return (
        <MainLayout>
            <LeadershipHero></LeadershipHero>
            <LeadershipTeam></LeadershipTeam>
        </MainLayout>
    )
}

export default Leadership